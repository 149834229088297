import React from "react";
import ChartFob from "../components/dashboard/ChartFob";

export default function PageDashboard() {
    return (
        <main className="container-fluid">
            <h1>Inicio</h1>
            <ChartFob/>
        </main>
    )
}